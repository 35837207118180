
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
$content-width: 1280px;
$blue-color: #4c70ff;

.layout {
  position: relative;
  min-height: 100vh;
  height: 100vh;
}

.content {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  &Left {
    position: relative;
    width: 41.66vw;
    min-height: 100vh;
    min-width: 500px;
    background-size: 100% 100% !important;
  }
  &Logo {
    position: relative;
    padding: 24px 32px;
    z-index: 9;
  }
  .addTraffic {
    color: #000;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    margin-top: 120px;
  }
  .addTrafficSub {
    padding-bottom: 10px;
    margin-top: 16px;
    color: #fff;
    text-align: center;
    font-size: 64px;
    font-weight: 900;
    line-height: 75px;
    letter-spacing: 0.927px;
    background: linear-gradient(136deg, #ff006a 12.41%, #ff1f50 48.59%, #fe6711 95.57%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.policy {
  width: 550px;
  padding-bottom: 10px;
  position: absolute;
  bottom: 21px;
  left: 0;
  min-width: 500px;
  text-align: center;
  a {
    color: rgba(28, 28, 28, 0.6);
    font-size: 12px;
  }
}
.bgImg {
  min-width: 486px;
}

.typography {
  &__title {
    margin: 24px 0 0;
    color: $primary-text-color;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 53px;
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-bottom: 12px;
    color: #2d3036;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
.back {
  display: flex;
  color: #2d3036;
}
.langSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  padding: 20px 24px 0;
  margin-bottom: 10px;
  :global {
    .ant-select {
      width: 100px;
    }
    .ant-select-selector {
      border: none !important;
      &:hover,
      &:focus,
      &:active {
        border: none !important;
      }
    }
  }
}
.childrenContainer {
  display: flex;
  justify-content: center;
  height: calc(100% - 72px);
  align-items: center;
}
.goHome {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9da4ae;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  cursor: pointer;
  img {
    margin-right: 4px;
  }
}
.contentLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 550px;
}

.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 40px;
  .addSub {
    height: 248px;
    width: 486px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
// .footerDivider {
//   margin: 16px 0;
// }
.footer {
  position: fixed;
  bottom: 0;
  height: 56px;
  background-color: #fff;
}
@media (min-width: 555px) and (max-width: 1200px) {
  .contentLeft {
    display: none;
  }
  .footer {
    width: 100%;
  }
  .footerDivider {
    margin: 0;
  }
  .goHome {
    height: 100%;
  }
}
@media (min-width: 555px) {
  .childrenContainer {
    overflow: auto;
  }
}

@media (min-width: 1200px) {
  .layout {
    overflow: hidden;
  }
  .footer {
    width: calc(100% - 550px);
    .goHome {
      height: 100%;
    }
  }
  .footerDivider {
    margin: 0;
  }
}

@media (min-width: 350px) and (max-width: 554px) {
  .body {
    padding-top: 30px;
  }
  .contentLeft {
    display: none;
  }
  .footer {
    display: none;
  }
  .langSelect {
    width: 100%;
    justify-content: space-between;
    left: 0;
    right: 0;
    background-color: #fff;
  }
}
.goHomeText {
  color: #808591;

  &:hover {
    color: #ff7340;
  }

  &:active {
    color: #d93209;
  }
}
