
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
.localWrapper {
  .infoBtn,
  .listBtn {
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: $primary-text-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 150% */
    padding: 8px 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    user-select: none;
  }

  .listBtn {
    padding: 16px 24px;
    color: #808591;
    background-color: #f6f6fa;

    b {
      margin: 0 5px;
    }

    @include noneBorderButtonStyles($info-color, $info-active-color, #f0f6ff);
  }

  :global {
    .ant-modal-wrap {
      pointer-events: none !important;
    }

    .ant-modal {
      position: absolute !important;
      top: 88px;
      right: 24px;
      margin: 0;
      padding: 0;

      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        color: $primary-text-color;

        span {
          color: #b6bcc7;
        }
      }

      .ant-modal-body {
        padding: 0;
      }

      .ant-modal-footer {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.infoContent {
  display: flex;
  padding: 32px;
  padding-bottom: 24px;
  .icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1972f5;
    box-shadow: 0px 12px 28px -2px rgba(41, 83, 192, 0.2);
    margin-right: 16px;
  }
  .text {
    .title {
      color: $primary-text-color;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 133.333% */
      margin-bottom: 8px;
    }
    .desc {
      word-wrap: break-word;
      white-space: normal;
      width: 212px;
      color: #4b5563;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px; /* 150% */
      span {
        word-wrap: break-word;
      }
      .num {
        // float: left;
        color: #2953c0;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
