
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
@import './custom-class.scss';
@import './variables.scss';
$primary-color: #ff4e16;

$font-family: 'HarmonyOS Sans SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
:root {
  --font-family: $font-family;
  --primary-color: $primary-color;
  --primary-bg-color: $primary-bg-color;
  --text-color: $text-color;
  --gray-color: $gray-color;
  --border-radius-base: $border-radius-base;
  --border-radius-sm: $border-radius-sm;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
  font-family: $font-family;
}

html {
  text-size-adjust: 100%;
}

body {
  font-family: var(--font-family);
  font-size: 14px;
  background-color: var(--primary-bg-color);
}

html,
body,
#root,
// #__next,
#app {
  height: 100%;
}
#__next {
  overflow: hidden;
}

#root {
  position: relative;
}

.icon-spin {
  -webkit-animation: icon-spin 0.7s infinite linear;
  animation: icon-spin 0.7s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

/**
 * 全局滚动条设置
 */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 4px;
  min-height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.nobreak-tooltip {
  .ant-tooltip-inner {
    padding: 12px 16px;
    border-radius: 4px;
    white-space: nowrap;
  }
  .ant-tooltip-content {
    width: max-content;
  }
}

.empty_table {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    color: #ff9a75;
  }
}

:where(.css-dev-only-do-not-override-bkuyz0).ant-form-item .ant-form-item-label > label {
  font-size: 14px;
}
:where(.css-dev-only-do-not-override-1gzzekz).ant-input-affix-wrapper .ant-input-clear-icon {
  color: #d9dee5;

  &:hover {
    color: #808591;
  }
}
:where(.css-dev-only-do-not-override-1x8kbao).ant-input-affix-wrapper .ant-input-clear-icon {
  font-size: 16px;
  color: #d9dee5;
}

:where(.css-dev-only-do-not-override-1gzzekz).ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: #d9dee5;
  font-weight: 1600;

  &:hover {
    color: #808591;
  }
}
.noRequireIcon {
  .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}

.ant-form-item-required {
  display: inline-flex;
  flex-direction: row-reverse;
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  padding-left: 2px;
  display: inline-block;
  margin-inline-end: 4px;
  color: #2d3036;
  font-size: 14px;
  line-height: 1;
  content: '*';
}

.ant-form-item .ant-form-item-label > label::after {
  content: '';
  display: none;
}
// 摆脱浏览器使用历史记录时的填充色
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: inherit; // 设置填充不变色
  transition: background-color 50000s ease-in-out 0s;
}

.ant-input {
  height: 38px;
}

.ant-select {
  // font-size: 14px;
  height: 40px;
}
.ant-input-affix-wrapper .ant-input {
  height: 38px;
}
.ant-input-number-affix-wrapper {
  width: 100%;
  height: 40px;
  input {
    height: 38px;
  }
}
.ant-input-number-suffix,
.ant-input-number-prefix {
  color: #9da4ae;
}
.ant-input-number {
  input {
    height: 38px;
  }
}
.ant-input-number-input {
  padding-right: 10px !important;
}
.ant-input-number-handler-wrap {
  display: none !important;
}
.ant-input-prefix {
  padding-left: 8px;
  color: #9da4ae;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-input-suffix {
  color: #9da4ae;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
// textarea 字数限制显示位置
.ant-input-textarea-show-count .ant-input-data-count {
  top: -22px;
}

// 全局DatePicker样式自定义
.ant-picker-cell-inner {
  border-radius: 50% !important;

  &::before {
    border-radius: 50% !important;
  }
}

.ant-modal .ant-modal-content {
  padding: 0;
  border-radius: 16px;
  .ant-modal-header {
    margin-bottom: 0;
    padding: 20px 24px;
    border-bottom: 1px solid #f2f4f7;
  }
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-footer {
    padding: 12px 24px;
    border-top: 1px solid #f2f4f7;
  }
}

.ant-modal-footer {
  .submit-btn-custom {
    min-width: 68px;
    height: 36px;
    background: linear-gradient(96.16deg, #ff385d 0%, #ff472e 100%);
    border: 0;
    color: #fff !important;
    padding: 4px 20px;
  }
}

// 全局自定义message样式
.ant-message-notice-content {
  padding: 0 !important;
  .ant-message-info,
  .ant-message-error,
  .ant-message-warning,
  .ant-message-success {
    background: #f6ffed;
    border: 1px solid #73d13d;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    color: #2e2f33;
    font-family: 'PingFang SC', Arial, sans-serif;
    padding: 10px 16px;
  }
  .ant-message-info {
    background: #f0f6ff;
    border: 1px solid rgba(49, 94, 255, 0.2);
    box-sizing: content-box;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 12px;
  }
  .ant-message-warning {
    background: #fffbe6;
    border: 1px solid #faad14;
  }
  .ant-message-error {
    background: #fff1f0;
    border: 1px solid rgba(255, 77, 79, 0.5);
  }
}

.ant-layout {
  // background: #f8f9fa;
  background: #fff;
  overflow-x: auto;
}
// 全局自定义tooltip样式
.ant-tooltip {
  max-width: 480px;
}

.h5_tooltip {
  max-width: 320px;
}

.ant-tooltip-inner {
  max-width: 80vw;
  max-height: 40vh;
  overflow: scroll;
}

.ant-btn {
  border-radius: 999px;
}

.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  cursor: not-allowed;
  border: none;
  background-color: $button-primary-disabled-bg;
  color: $button-primary-disabled-text-color;
  box-shadow: none;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):focus {
  color: $primary-text-color;
  background-color: $container-background-color;
  border: 1px solid #e5e7eb;
}
//渐变button
.firstTypeBtn {
  background: linear-gradient(270deg, #ff7d41 0%, #fc3f38 100%) !important;
  box-shadow: 0px 8px 32px -16px #fc3f38;
  &:not(:disabled):not(.ant-btn-disabled):active,
  &:not(:disabled):not(.ant-btn-disabled):hover {
    background: linear-gradient(270deg, #ff7d41 0%, #fc3f38 100%);
    box-shadow: 0px 8px 32px -16px #fc3f38;
  }
}

//渐变2无阴影
.secondTypeBtn,
.secondTypeBtn:not(:disabled):not(.ant-btn-disabled):active,
.secondTypeBtn:not(:disabled):not(.ant-btn-disabled):focus,
.secondTypeBtn:not(:disabled):not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: linear-gradient(90deg, #ff9549 0%, #ff4e33 100%);
  box-shadow: 0px 4px 12px 0px rgba(230, 70, 46, 0.12);
}

.secondTypeBtn:disabled {
  color: #fff;
  background: $button-primary-disabled-text-color;
  box-shadow: none;
}

.btnFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
//table
.ant-table-header {
  border-radius: 8px;

  .ant-table-thead {
    th {
      background-color: #fff;
    }
    &:hover {
      th {
        background-color: #fafafa;
      }
    }
  }
}

.ant-table {
  font-family: $font-family;
  td {
    border-right: none !important;
  }
  table {
    border-top: none;
  }
  .ant-table-thead > tr > th {
    padding: 12px 16px;
    border-right: none;
  }

  .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background-color: #fff !important;
  }
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: none;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
  border-right: none;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: none;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-table-thead > tr > th:not(:last-child, .ant-table-selection-column, .ant-table-row-expand-icon-cell, [colspan])::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before {
  background-color: #e6e7eb !important;
}
// .ant-table-sticky-holder {
//   top: 64px !important;
// }
// fixed 列阴影
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: -1px 0 2px -1px rgba(0, 0, 0, 0.03), -2px 0 4px -1px rgba(0, 0, 0, 0.03) !important;
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: 1px 0 2px -1px rgba(0, 0, 0, 0.03), 2px 0 4px -1px rgba(0, 0, 0, 0.03) !important;
}

.ant-pagination {
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
    border-radius: 50%;

    &:not(.ant-pagination-item-active):hover {
      background-color: $primary-bg-color;
    }
  }
  .ant-pagination-item-active {
    background: #fa4b3a;
    border-radius: 50%;
    font-weight: 400;

    a,
    &:hover a {
      color: #fff;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }
}

.empty_table {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    color: #ff9a75;
  }
}
// 全局表单项样式\
.ant-input,
.ant-picker {
  height: 40px;
  padding: 4px 11px;
  border-radius: 8px !important;
  &:hover,
  &:focus {
    border-color: #ff7057 !important;
  }
}
.ant-input-affix-wrapper {
  padding: 0 !important;
  border-radius: 8px !important;
  &:hover,
  &:focus,
  &:target {
    border-color: #ff7057 !important;
  }
}
.ant-input-affix-wrapper:focus-within {
  border-color: #ff7057 !important;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 8px 0 0 8px !important;
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 4px 11px !important;
}
.ant-input-suffix {
  padding: 4px 11px 4px 0;
}
.ant-select:not(.ant-select-disabled) .ant-select-selector {
  display: flex;
  align-items: center;
  min-height: 40px;
  border-radius: 8px !important;

  &:hover,
  &:focus {
    border-color: #ff7057 !important;
  }
}
.ant-select-open {
  .ant-select-selector {
    border-color: #ff7057 !important;
  }
}
.ant-select-arrow {
  transition: all 0.3s;
}

.ant-select-open .ant-select-arrow {
  transform: rotate(180deg);
}

.ant-popover-inner {
  border-radius: 8px;
  box-shadow: 0 8px 30px -3px rgba(0, 0, 0, 0.06);
}
.ant-dropdown-menu {
  border-radius: 8px;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.03), 0 10px 15px -3px rgba(0, 0, 0, 0.06);
}
.ant-checkbox .ant-checkbox-inner {
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-text-color;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: $primary-text-color;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: $primary-text-color;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border: 1px solid #d1d5db;
  background: #d1d5db;
  &::after {
    border-color: #fff;
  }
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $primary-text-color;
}
.ant-select-item {
  display: flex;
  align-items: center;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff;
}
.ant-select-item-option-selected::after {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-image: url('#{$static-assets-url}/common/check.svg');
  background-size: 100% 100%;
}
.ant-pagination-options {
  .ant-select-item-option-selected::after {
    content: '';
    display: none;
  }
}

.ant-cascader-dropdown .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-dropdown .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #f9f9f9;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline,
.ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}
.ant-tabs .ant-tabs-ink-bar {
  color: $primary-text-color;
  background-color: $primary-text-color;
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #9da4ae;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-text-color;
}
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab-remove:active {
  color: $primary-text-color !important;
}

.textarea-show-count {
  position: relative;
  .ant-input-data-count {
    position: absolute;
    top: -28px;
    right: 0;
  }
  textarea {
    height: 88px !important;
  }
}

.input-show-count {
  .ant-input-show-count-suffix {
    position: absolute;
    top: -28px;
    right: 0;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.span-link {
  color: #2953c0;
  cursor: pointer;
  font-weight: 500;
}

.clearFormMargin {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.optionBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #202736;
  cursor: pointer;
  &:not([data-disabled='true']) {
    @include optionTextStyles(#f0f6ff);
  }

  &[data-disabled='true'] {
    cursor: not-allowed;
  }
}
.ant-empty .ant-empty-description {
  color: #9da4ae;
}

.ant-image {
  overflow: hidden;
  .transform-img {
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.4);
    }
  }
}

.ban_touch {
  touch-action: none;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  overflow: hidden;
}
