
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
// 空界面
.empty {
  padding: 24px;
  background-color: #fff;
  border-radius: 20px;
  height: 558px;
  display: flex;
  justify-content: center;
  align-items: center;
  .emptyContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .emptyTitle {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 27px;
    }
  }
}
