
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
.locale {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  border-radius: 8px;
  padding: 8px;
  color: #808591;
  cursor: pointer;
  &:hover {
    background-color: $tertiary-fill-color;
  }
}
@media (max-width: 554px) {
  .locale {
    margin-bottom: 0 !important;
  }
}
