.text-center {
  text-align: center;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

// display
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

// flex box
.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

// margin
.mb-0 {
  margin-bottom: 0;
}

.text-current {
  color: currentcolor;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

.openButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  padding: 6px 12px;
  height: auto;
  height: 28px;
  display: none;
  border: 1px solid #eceff4;
  box-shadow: 0px 2.4px 18px 0px rgba(0, 0, 0, 0.06);
  color: #2d3036;

  &:not(:disabled):not(.ant-btn-disabled) {
    @include noneBorderButtonStyles($info-color, $info-active-color, #f0f6ff);
  }

  svg {
    display: inline-flex;
    align-items: center;
  }
}
