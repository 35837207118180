
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
.content {
  display: flex;
  align-items: center;
  cursor: pointer;
  .imgs {
    margin-right: 6px;
    position: relative;
    min-height: 28px;
    .avatar {
      border-radius: 4px;
      border: 0.5px solid $secondary-bg-color;
    }
    .platformIcon {
      position: absolute;
      bottom: 2px;
      right: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10px;
      height: 10px;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  &:hover {
    color: $info-color;
    text-decoration: underline;
  }
  &:active {
    color: $info-active-color;
    text-decoration: underline;
  }
}
