
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
.notification {
  padding: 24px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #f1f3f6;

  &:hover {
    background-color: #f6f6fa;
  }

  .notificationIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #ffe7de;
    background: #fff8f4;
    position: relative;
    flex-shrink: 0;
    align-self: flex-start;

    :global .ant-badge-dot {
      transform: none;
      top: 2px;
      right: 2px;
      width: 8px;
      height: 8px;
    }
  }

  .messageInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .time {
      color: #808591;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
    }

    .message {
      font-size: 16px;
      line-height: 22px;
      color: #2f3746;
      & > span {
        color: $primary-text-color;
        font-weight: 500;
        padding: 0 8px;
        .platformIcon {
          padding: 0;
          line-height: 24px;
          display: inline-block;
          margin-right: 2px;
          svg {
            width: 20px;
            height: 20px;
            vertical-align: -4px;
          }
        }
      }
      .canClick {
        cursor: pointer;
      }
      .canClick:hover {
        color: $info-color;
      }
      .canClick:active {
        color: $info-active-color;
      }
    }
  }
}

.clickable {
  cursor: pointer;

  &:active,
  &:focus {
    background-color: #eceff4;
  }
}

.nonInteractive {
  cursor: default;
  &:hover {
    background-color: #fff;
  }
}
