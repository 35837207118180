$font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Colors
$primary-color: #ff4e16;
$primary-hover-color: #ff7340;
$primary-active-color: #d93209;

$primary-text-color: #1c1c1c;
$primary-bg-color: #fff0e6;
$diabled-border-color: #f1f3f6;
$secondary-bg-color: #eceff4;

$secondary-border-color: #d9dee5;

$text-color: #2d3036;
$text-size: 14px;
$sub-text-size: 12px;

$secondary-color-hover: #ff4244;
$secondary-color-active: #d92e36;

$gray-color: #cbcbcb;

$border-radius-base: 4px;

$border-radius-sm: 2px;

$mainFont-color: #232426;

/* 次要文字色 */
$secondary-color: #9c9ea6;

$content-min-width: 980px;

$button-primary-disabled-bg: #f6f6fa;
$button-primary-disabled-text-color: #b6bcc7;
$container-background-color: #f8f9fa;
$tertiary-fill-color: #f6f6fa;
$container-padding: 32px;
$content-margin: 24px;

$info-color: #315eff;
$info-active-color: #1e41d9;
$error-color: #ff4244;
$error-active-color: #d92e36;

@mixin noneBorderButtonStyles($hover-color, $active-color, $bgColor) {
  &:hover {
    color: $hover-color !important;
    background-color: $bgColor;
  }

  &:active,
  &:focus {
    color: $active-color !important;
    background-color: $bgColor;
  }
}

@mixin borderButtonStyles($hover-color, $active-color, $bgColor) {
  @include noneBorderButtonStyles($hover-color, $active-color, $bgColor);

  &:hover {
    border: 1px solid $hover-color;
  }

  &:active,
  &:focus {
    border: 1px solid $active-color;
  }
}

@mixin optionTextStyles($background-color) {
  @include noneBorderButtonStyles($info-color, $info-active-color, $background-color);
}

$color-text-secondary: #808591;
