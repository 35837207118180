
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
.notificationBell {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &:hover::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    background-color: #f2f4f7;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; // 确保背景圆圈在铃铛图标之下
  }

  svg {
    position: relative;
    z-index: 2; // 确保铃铛图标在 hover 效果之上
  }

  .notificationCount {
    position: absolute;
    top: -9px;
    right: -9px;
    background-color: #fc3f38;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    // 防止数字过大溢出
    min-width: 20px;
    padding: 0 6px;
    box-sizing: border-box;
    z-index: 3;
  }
}
